<template>
  <div>
    <div class=" d-flex w-100">
      <div class="sliderIcon mr-2" @click="reduceValue">
        <div>
          <i class="fas fa-minus px-2" :style="{color:rangeColor}"></i>
        </div>
      </div>
      <div style="width: inherit">
      <p class="d-none">{{defaultValue}}</p>
        <vue-slider
          v-model="value"
          :min="0"
          :max="100"
          :process-style="{ backgroundColor: rangeColor }"
          :style="[
            { '--railColor': checkColor },
            { '--handleColor': rangeColor },
          ]"
          :tooltip="'always'"
          :tooltip-formatter="formatter"
          @change="chnageValue"
        />
      </div>
      <div class="sliderIcon ml-2" @click="increaseValue">
        <div>
          <i class="fas fa-plus px-2" :style="{color:rangeColor}"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
export default {
  props: {
    rangeColor: {
      type: String,
      default: "#0033ff",
    },
    defaultValue: {
      type: Number,
      default: 15,
    },
  },
  components: {
    VueSlider,
  },
  computed: {
    checkColor() {
      var data = this.rangeColor.split("");
      if (data[0] === "r" && data[1] === "g" && data[2] === "b") {
        var rgbColor = this.rangeColor.split("(")[1].split(")")[0];
        rgbColor = rgbColor.split(",");
        var hexColor =
          "rgb(" +
          rgbColor[0] +
          "," +
          rgbColor[1] +
          "," +
          rgbColor[2] +
          ",0.3)";
        return hexColor;
      } else {
        if (this.rangeColor.includes("#")) {
          return (
            "rgb(" +
            this.hexToRgb(this.rangeColor).r +
            "," +
            this.hexToRgb(this.rangeColor).g +
            "," +
            this.hexToRgb(this.rangeColor).b +
            ",0.3)"
          );
        } else {
          return this.rangeColor;
        }
      }
    },
  },
  data() {
    return {
      value: 5,
      formatter: "{value}%",
    };
  },
  created() {
    this.value = this.defaultValue;
  },
  beforeUpdate() {
    this.value = this.defaultValue;
  },
  methods: {
    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    },
    chnageValue(e) {
      this.value = e;
      this.$emit("updatedValue", e);
    },
    reduceValue(){
      this.value--
      this.$emit("updatedValue", this.value);
    },
    increaseValue(){
      this.value++
      this.$emit("updatedValue", this.value);
    }
  },
};
</script>

<style>
.vue-slider-rail {
  background-color: var(--railColor);
}
.vue-slider-dot-handle {
  border: 6px solid #fff;
  height: 25px;
  width: 25px;
  background-color: var(--handleColor);
  margin-top: -6px;
}
.vue-slider.vue-slider-ltr {
  height: 15px !important;
}
.vue-slider-dot-tooltip-top {
  top: -5px;
  left: 100%;
}
.vue-slider-dot-tooltip-inner {
  background-color: transparent;
  color: #222a37;
  font-size: medium;
  border-color: transparent;
  font-family: ProximaNovaBold;
}
.sliderIcon {
  width: auto;
  height: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  background-color: #f3f3f3;
}
.sliderIcon i {
  font-size: 20px;
}
</style>
